import React, { useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';  // Ensure this is included
import './WorkProcess.css';

const WorkProcess = () => {
  useEffect(() => {
    const subtitle = document.querySelector('.work-process-subtitle');

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    if (subtitle) {
      observer.observe(subtitle);
      const steps = document.querySelectorAll('.work-process-step');
      steps.forEach(step => {
        observer.observe(step);
      });
    }
  }, []);

  const steps = [
    { id: 1, title: "Start Consultation", icon: "bi-person-circle", number: "01" },
    { id: 2, title: "Design Installation", icon: "bi-rulers", number: "02" },
    { id: 3, title: "Start Construction", icon: "bi-tools", number: "03" },
    { id: 4, title: "And Finished Work", icon: "bi-check2-circle", number: "04" },
  ];

  return (
    <section className="work-process mb-5">
      <div className="container">
        <p className="work-process-subtitle">Work Process</p>
        <h2 className="work-process-title">We Complete Every Step Carefully</h2>

        <div className="work-process-steps">
          {steps.map((step) => (
            <div className="work-process-step" key={step.id}>
              <i className={`bi ${step.icon} step-icon`}></i>
              <p className="step-title">{step.title}</p>
              <div className="step-number">
                <span>{step.number}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    
  );
};

export default WorkProcess;
