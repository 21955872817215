import React from 'react';
import gardenImage1 from '../Images/Garden-1.jpeg'; 
import gardenImage2 from '../Images/Garden-2.jpeg';
import './StoryComponent.css';
const StoryComponent = () => {
  
  // const VideoComponent = () => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  return (
    <div className="story-section container-fluid">
      <div className="row">
        <p className="highlight-text">Welcome to Hydro Heaven – Cultivating Nature’s Beauty</p>
        <h2 className='tagline'>Our Story: Why Hydro Heaven?</h2>
        <div className="col-lg-6 col-md-5 story-content">
          <p>
            At Hydro Heaven, our journey sprouted from a passion for transforming spaces into flourishing havens
            of natural beauty. We recognized the importance of harmonizing the built environment with nature, and thus,
            Hydro Heaven was born. Our roots delve deep into the soil of commitment, innovation, and a vision to
            create landscapes that captivate and inspire.
          </p>
          <ul className="benefits-list">
            <li>✔ Fresh Produce</li>
            <li>✔ Urban Oasis</li>
            <li>✔ Sustainable Living</li>
            <li>✔ Innovative Gardening</li>
            <li>✔ Healthy Harvest</li>
          </ul>
          <button className="learn-more-btn">Learn More</button>
          
        </div>

        <div className="col-lg-6 col-md-7 story-images">
          <div className="image-group">
            <img src={gardenImage1} alt="Garden" className="main-image" />
            <img src={gardenImage2} alt="Gazebo" className="small-image" />
          </div>
          {/* <div className="projects-done">
            <span className="projects-number">200+</span>
            <p className='Projects-text'>Projects Done</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StoryComponent;
