import React from 'react';
// import Contact_page_image from '../Images/contact_page_hero.jpg';
import './ContactSection.css';

const ContactSection = () => {
  return (

      <div className="contact-row">
          {/* <img src={Contact_page_image} width={1200} alt='this is garden'/> */}
          {/* First Column */}
          <div className="col-12 col-lg-6">
            <div className="contact-card">
              <div className="contact-card-body">
                <h2 className="contact-card-title">Business Proposals:</h2>
                <h6 className="contact-card-subtitle my-2">Let's discuss something cool together.</h6>
                <p className="contact-card-text">
                  Contact us to make your environment worth living in, breathtaking, and clean.
                  We are here to elevate and laminate your office and house interiors and exteriors,
                  making them glamorous.
                </p>
              </div>

              <div className="social-media">
                <a href="https://youtu.be/-Y5fpzP1x9s?feature=shared" target="_blank" rel="noopener noreferrer">
                  <img src="https://img.icons8.com/color/48/000000/youtube.png" alt="YouTube" />
                </a>
                <a href="mailto:hydroheavenofficial@gmail.com" target="_blank" rel="noopener noreferrer">
                  <img src="https://img.icons8.com/color/48/000000/gmail.png" alt="Gmail" />
                </a>
                <a href="https://linkedin.com/in/hydro-heaven-3366b2313" target="_blank" rel="noopener noreferrer">
                  <img src="https://img.icons8.com/color/48/000000/linkedin.png" alt="LinkedIn" />
                </a>
                <a href="https://www.facebook.com/people/Hydroheaven/100065165965909" target="_blank" rel="noopener noreferrer">
                  <img src="https://img.icons8.com/color/48/000000/facebook.png" alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/officialhydroheaven/" target="_blank" rel="noopener noreferrer">
                  <img src="https://img.icons8.com/color/48/000000/instagram-new.png" alt="Instagram" />
                </a>
                <a href="https://x.com/HydroHeaven2024?t=KMBfMNujJ9M8hgPPNfeavA&s=08" target="_blank" rel="noopener noreferrer">
                  <img src="https://img.icons8.com/color/48/000000/twitter--v1.png" alt="Twitter" />
                </a>
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="col-12 col-lg-6">
            <div className="contact-details">

              <h1>Contact Us Here:</h1>
              
              <h3>Hydro Heaven,</h3>
              
              <p className="contact-address">
                Regional Plan 9,
                <br /><span>University of Agriculture Faisalabad, 38000.</span>
              </p>
              
              <a className='cell-number' href="tel:+923226596698"><strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                </svg> 0322-6596698</strong>
              </a>
              
              <a className='cell-number' href="tel:+923167533717"><strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                </svg> 0316-7533717</strong>
              </a>
            
            </div>
          </div>
      </div>
  );
};

export default ContactSection;
