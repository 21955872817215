import React from 'react';
import Header from '../../Components/Header/ContactHeader';
import Navbar from '../../Components/Navbar/Navbar';
import ContactSection from '../../Components/ContactSection/ContactSection';
import Footer from '../../Components/Footer/Footer';
function Contact() {
  return (
    <> 
    <div className="container-fluid p-0 m-0"> 

        <Header/>
        <Navbar/>
        
        <ContactSection />
        <Footer/>
      </div>
    </>
  );
}

export default Contact;
