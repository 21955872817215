import React from 'react';
import './productCards.css'; // Change this to SCSS for better maintainability
import Prod1 from '../Images/Grass.jpg';
import Prod2 from '../Images/net.jpg';
import Prod3 from '../Images/light.jpg';
import Prod4 from '../Images/CVSO.jpg';
import Prod5 from '../Images/Conenctor.jpg';
import Prod6 from '../Images/Drip Emitters or Sprayers.jpg';
import Prod7 from '../Images/Float Valve.jpg';
import Prod8 from '../Images/Nutrient Solution.jpg';
import Prod9 from '../Images/pvc pipe.jpg';
import Prod10 from '../Images/PH.jpg';
import Prod11 from '../Images/R.jpg';
import Prod12 from '../Images/Timer.jpg';
import Prod13 from '../Images/submercibale pumpe.jpg';
import Prod14 from '../Images/Support Frame or Stand.jpg';
import Prod15 from '../Images/Tubing and Connectors.jpg';

// Define the product data array
const products = [
  { name: "Tubing and Connectors", image: Prod15, price: 29.99, description: "A great product." },
  { name: "Support Frame and Stand", image: Prod14, price: 29.99, description: "A great product." },
  { name: "Submersible Pump", image: Prod13, price: 29.99, description: "A great product." },
  { name: "Timer", image: Prod12, price: 29.99, description: "A great product." },
  { name: "Compost", image: Prod11, price: 29.99, description: "A great product." },
  { name: "pH Reader", image: Prod10, price: 29.99, description: "A great product." },
  { name: "PVC pipes", image: Prod9, price: 29.99, description: "A great product." },
  { name: "Nutrient Solutions", image: Prod8, price: 29.99, description: "A great product." },
  { name: "Float Valve", image: Prod7, price: 29.99, description: "A great product." },
  { name: "Drip Emitters and Sprayers", image: Prod6, price: 29.99, description: "A great product." },
  { name: "Grass", image: Prod1, price: 29.99, description: "A great product." },
  { name: "Net Pots and Cups", image: Prod2, price: 49.99, description: "(PACK OF 100) 3 Inch Net Pots" },
  { name: "Light", image: Prod3, price: 19.99, description: "A budget-friendly option." },
  { name: "Check Valves and Shut-Off", image: Prod4, price: 19.99, description: "A budget-friendly option." },
  { name: "Connectors", image: Prod5, price: 19.99, description: "A budget-friendly option." }
];

// Single Product Card Component
const ProductCard = ({ product }) => {
  return (
    <article className="col-lg-3 col-md-4 col-sm-6 mb-4">
      <div className="custom-product-card card h-100">
        <img
          src={product.image}
          alt={product.name}
          className="custom-product-image card-img-top"
        />
        <div className="custom-product-body card-body">
          <h5 className="custom-product-name card-title">{product.name}</h5>
          <p className="custom-product-price card-text">${product.price}</p>
          {/* <p className="custom-product-description card-text">{product.description}</p> */}
        </div>
      </div>
    </article>
  );
};

// Main Product Cards Component (Carousel/Grid)
const ProductCards = () => {
  return (
    <section className="container py-5">
      <div className="row">
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
    </section>
  );
};

export default ProductCards;
