import React from 'react';
import './CustomerReview.css'; // Import the CSS for styling
import Abdulhadi from '../Images/Abdul Hadi.png';
import Nouman from '../Images/Nouman.png';

const reviews = [
  { name: 'Mr. Abdul Hadi', rating: 5, text: 'I have renovated my lawn and they have done amazing service!', photo: Abdulhadi },
  { name: 'Mr. Nouman', rating: 4, text: 'I was afraid to see students, doing this much heavy work but they impressed me. I am very satisfied!', photo: Nouman },
  { name: 'Mr. Abdul Hadi', rating: 5, text: 'I have renovated my lawn and they have done amazing service!', photo: Abdulhadi },
  { name: 'Mr. Nouman', rating: 4, text: 'I was afraid to see students, doing this much heavy work but they impressed me. I am very satisfied!', photo: Nouman },
  { name: 'Mr. Abdul Hadi', rating: 5, text: 'I have renovated my lawn and they have done amazing service!', photo: Abdulhadi },
  { name: 'Mr. Nouman', rating: 4, text: 'I was afraid to see students, doing this much heavy work but they impressed me. I am very satisfied!', photo: Nouman },
  
];

const ReviewCard = ({ review }) => (
  <div className="container col-lg-4 col-md-6 ">
  <div className="review-card">
    <img src={review.photo} alt={review.name} className="review-photo" />
    <div className="review-content">
      <h3>{review.name}</h3>
      <div className="review-rating">
        {'★'.repeat(review.rating)}
        {'☆'.repeat(5 - review.rating)}
      </div>
      <p>{review.text}</p>
    </div>
  </div>
</div>
  
);

const CustomerReview = () => (
  <div className="customer-reviews">
    <h2>What Our Customers Say</h2>
    <div className="reviews-summary">
      <span>4.5</span>
      <span>Based on 200 reviews</span>
    </div>
    <div className="reviews-container">
      {reviews.map((review, index) => (
        <ReviewCard review={review} key={index} />
      ))}
    </div>
  </div>
);

export default CustomerReview;

