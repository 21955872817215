import React from 'react'
import ContactHeader from '../../Components/Header/ContactHeader'
import Navbar from '../../Components/Navbar/Navbar'
import WhatsAppButton from '../../Components/Whatsapp/Whatsapp'
import CustomerReview from '../../Components/CustomerReviews/CustomerReview'
import Footer from '../../Components/Footer/Footer'
import ProductsHero from '../../Components/ProductsHero/ProductsHero'
import ProductCards from '../../Components/productCards/productCards'


export default function Products() {
  return (
    <div className="container-fluid p-0 m-0"> 
    <ContactHeader />
    <Navbar/>
    <WhatsAppButton/>
    <ProductsHero/>
    <ProductCards/>
    <CustomerReview/>
    <Footer/>
</div>
       
  )
}
