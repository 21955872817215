import React from 'react';
import Header from '../../Components/Header/ContactHeader';
import Navbar from '../../Components/Navbar/Navbar';
import WhatsAppButton from '../../Components/Whatsapp/Whatsapp';
import Carousels from '../../Components/Carousels/Carousels';
import Footer from '../../Components/Footer/Footer';
import WhyUs from '../../Components/Images/whyus.webp';
import StoryComponent from '../../Components/StoryComponent/StoryComponent';
import './Home.css';
import LandscapeComponent from '../../Components/LandscapeComponent/LandscapeComponent';
import WorkProcess from '../../Components/WorkProcess/WorkProcess';

const cardData = [
  {
    id: 1,
    title: "30",
    subtitle: "SPECIALISTS",
    subtitle2: "Hydroponics & Roof Top Farming",
    text: "Our team of experts has the knowledge and skills to design and implement successful rooftop farming projects."
  },
  {
    id: 2,
    title: "200+",
    subtitle: "SUCCESSFUL",
    subtitle2: "Projects",
    text: "With over 200 projects under our belt, we have the experience and expertise to tackle any rooftop farming challenge."
  },
  {
    id: 3,
    title: "5",
    subtitle: "YEARS",
    subtitle2: "Experience",
    text: "Our team has been pioneering rooftop farming for over 5 years, constantly innovating and improving our techniques."
  }
];

export default function Home() { 
  return (
    <>
<div className="container-fluid p-0 m-0">
      <Header />
      <Navbar />
      <WhatsAppButton />
      <Carousels />
      <StoryComponent/>
      <LandscapeComponent/>
      
      <div className="aboutUs row m-0">
      
      <h1>We handle everything for you</h1>
      <p>Your premier partner in Urban Transformation</p>
      
      {cardData.map((card) => (
        <div className="groupCard col-12 col-lg-4 col-md-6" key={card.id}>
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">{card.title}</h2>
              <h4 className="card-subtitle mb-2 text-body-success">{card.subtitle}</h4>
              <h6 className="card-subtitle-2 mb-2">{card.subtitle2}</h6>
              <p className="card-text">{card.text}</p>
            </div>
          </div>
        </div>
      ))}
      
      </div>
      
      <WorkProcess/>

      <div className="chooseUs row m-0">
        
        <div className="col-md-8">
          <div className="card-main-body">
              <h5 className="this-card-title">Why Choose Us</h5>
              <p className="this-card-text">
              Our team excels in rooftop gardening and hydroponics with years of experience and in-depth knowledge. We specialize in creating thriving urban spaces through customized solutions tailored to your needs and climate. Unlike one-size-fits-all approaches, we ensure results that exceed expectations. Plus, we prioritize sustainability and eco-friendly materials, believing that growing your own food should benefit both you and the planet. By choosing Hydro Heaven, you're not just getting expert guidance – you're also contributing to a more sustainable future.</p>
          </div>
        </div>

        <div className="col-md-4">
              <img src={WhyUs} className="img-fluid rounded-start" alt="Whyus" />
        </div>
     
     </div>
          

      <Footer />
</div>
    </>
  );
}
