import React from 'react'
import './AdditionalServiceSection.css'

const servicesInfos = [
  {
    card_subtitle:'Maintainance and Support:',
    card_text: 'We offer regular maintenance, monitoring, and support to ensure your rooftop garden and hydroponic system remain healthy and productive.',
  },
  {
    card_subtitle:'Hydroponic System Services:',
    card_text: 'Our team will design, install, and maintain hydroponic systems, providing optimal nutrients for your plants to flourish.',
  },
  {
    card_subtitle:'Rooftop Gardening:',
    card_text: 'We design and install customized rooftop gardens, using a variety of plants and materials to create a unique and thriving ecosystem.',
  }
];
  
export default function AdditionalServiceSection() {
  return (
    <>
          <div className="additional-services-section row m-0">
            <h2 className="section-title text-center">Our Services</h2>

              {servicesInfos.map((servicesInfo, index) => (
                <div key={index} className="someCards col-12 col-md-6 col-lg-4">
                  <div className="card additional-service-card">
                    <div className="card-body">
                      <h4 className="card-subtitle mb-2">{servicesInfo.card_subtitle}</h4>
                      <p className="card-text">{servicesInfo.card_text}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
    </>
  )
}
