import React from 'react'
import './ServiceSection.css'
import Consultancy from '../Images/consultancy.svg'
import Garden from '../Images/Gardening.jpg'
import Urban from '../Images/Urban.jpg'
import Maintainance from '../Images/maintainance.jpg'

const serviceCards = [
  {
    imgSrc: Maintainance,
    alt: 'Maintenance',
    title: 'Maintenance',
  },  
  {
      imgSrc: Urban,
      alt: 'Garden',
      title: 'Hydroponics Farm',
    },
    {
      imgSrc: Garden,
      alt: 'Urban',
      title: 'Roof Top Gardening',
    },
    {
      imgSrc: Consultancy,
      alt: 'Events',
      title: 'Consultancy',
    }
  ];
  
export default function ServiceSection() {
  return (
    <>
            <div className="services-section row m-0">
                <h1 className="services-title">We Are Studio Based in Faisalabad, that Provides Hassle-Free Roof Top Transformations with Artistic Solutions.</h1>
                {serviceCards.map((serviceCard, index) => (
                <div key={index} className="ourServices  col-lg-6 col-md-10 col-md-offset-1 col-12">
                  <div className="card service-card m-2">
                    <div className="card-img-wrapper">
                      <img src={serviceCard.imgSrc} className="card-img-top" alt={serviceCard.alt} />
                    </div>
                    <h5 className="card-title">{serviceCard.title}</h5>
                  </div>
                </div>
              ))}
           </div>
    </>
  )
}

// { 
// .services-section {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
      
//       .services-title{
//       font-family: $raleway;
//       margin: 20px 15px 30px;
//       font-size: 2.2rem;
//       font-weight: 200;
//       color: $background;
//       text-align: center;
//       width: 100%;
//     }
    
//     .ourServices {
//       border: 1px solid red;
//       padding: 10px;
//       margin-bottom: 20px;
//       box-sizing: border-box;
  
//       .service-card {
//         border: 1px solid $background;
//         margin: 0.5rem 15px;
//         border-radius: 2%;
//         height: 2.4in;
//         background-color: green;
//         overflow: hidden;
//         transition: transform 0.3s ease-in-out, box-shadow 0.3s;
//         position: relative;
  
//         &:hover {
//           transform: scale(1.05);
//           box-shadow: 0 10px 20px rgba($background, 0.5);
//         }
  
//         .card-img-wrapper{
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//           display: block;
//           opacity: 0.5;
//         }
  
//         .card-title{
//           font-size: 1.9rem;
//           font-weight: 600;
//           color: #fff;
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//         }
  
//       }
//     }
//   }
//   this styling is too biring, can you make it look better, i want it look like this picture. in this picture ignore the whatsapp buttons, please help me
// }