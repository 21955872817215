import React, { useState} from 'react';
import './FaqCompo.css';

const FAQSection = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'What is hydroponics?',
      answer: 'Hydroponics is a method of growing plants without soil by using mineral nutrient solutions in a water solvent.',
      isOpen: false,
    },
    {
      question: 'What are the benefits of hydroponics?',
      answer: 'Hydroponics allows for faster plant growth, higher yields, and the ability to grow plants in areas with poor soil quality or limited space.',
      isOpen: false,
    },
    {
      question: 'How much water does hydroponics use compared to soil gardening?',
      answer: 'Hydroponics uses up to 90% less water than traditional soil gardening, making it an environmentally friendly option.',
      isOpen: false,
    },
    {
      question: 'Can I grow any plant with hydroponics?',
      answer: 'Most plants can be grown hydroponically, but it’s especially effective for leafy greens, herbs, and certain fruiting plants like tomatoes and peppers.',
      isOpen: false,
    },
    {
      question: 'Do hydroponic systems require a lot of maintenance?',
      answer: 'Hydroponic systems require regular monitoring of water pH and nutrient levels, but they are generally low-maintenance once set up.',
      isOpen: false,
    }
  ]);

  const toggleFAQ = index => {
    setFaqs(faqs.map((faq, i) => {
      if (i === index) faq.isOpen = !faq.isOpen;
      return faq;
    }));
  };

  return (
    <div className='faq-row p-0 m-0'> 
            
        <div className="col-12 col-lg-6">
          <div className="intro-text">
            <h1>Welcome to the Future of Farming: Hydroponics</h1>
              <p>
                Hydroponics is revolutionizing the way we think about growing plants. With advanced techniques and innovative systems, this method allows for efficient, sustainable, and high-yield cultivation. Discover how hydroponics can transform your gardening experience and why it's becoming the preferred method for modern agriculture.
              </p>
          </div>  
        </div> 

        <div className="col-12 col-lg-6">
            <div className="faq-detail">
              <h2>Frequently Asked Questions</h2>
                {faqs.map((faq, index) => (
                  <div className={`faq-item ${faq.isOpen ? 'open' : ''}`} key={index}>
                    <div className="faq-question" onClick={() => toggleFAQ(index)}>
                      <span>{faq.question}</span>
                        <span className="faq-toggle">{faq.isOpen ? '−' : '>'}</span>
                    </div>
                    {faq.isOpen && (
                  <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
            </div>
        </div>
        
 
      
    
    </div>
    
  );
};

    
const MainComponent = () => (
  <div className="container-fluid">
    <div className="faq-row">
      <FAQSection />
    </div>
  </div>
);

export default MainComponent;
