import React from 'react';
import './Carousels.css';

// import Carl1 from '../Images/carousel-1.mp4';
import Carl1 from '../Images/carousel-1.mp4';
import Carl2 from '../Images/services-1.mp4';
import Carl3 from '../Images/services-2.mp4';
import Carl4 from '../Images/services-3.mp4';
const Carousel = () => {
  return (
  <div className="container-fluid p-0 m-0">  
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">

        {/* Video Carousel */}
        <div className="carousel-item active">
          <video className="d-block w-100" autoPlay loop muted>
            <source src={Carl1} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        </div>
        {/* Video Carousel */}
        <div className="carousel-item">
          <video className="d-block w-100" autoPlay loop muted>
            <source src={Carl2} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        </div>
        {/* Video Carousel */}
        <div className="carousel-item">
          <video className="d-block w-100" autoPlay loop muted>
            <source src={Carl3} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        </div>
        {/* Video Carousel */}
        <div className="carousel-item">
          <video className="d-block w-100" autoPlay loop muted>
            <source src={Carl4} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        </div>
      </div>
      
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    
    </div>
  
  </div>
  

  );
};

export default Carousel;
