import React from 'react';
import { Link } from "react-router-dom";
import './Footer.css';


export default function Footer() {
  return (
    <footer className='mt-5'>
    
      <div className="container text-center mt-5">
        <div className="row align-items-left" id="footerContentArea">
        
          {/*Address Section*/}
          <div className="footer-col col col-lg-4 col-md-6 col-sm-12 col-12">

            <span id="addr" className='mb-5'>RP-9, University of Agriculture, Faisalabad, Pakistan </span> <br/>
            <span id="mailAdd" className="my-0.5">hydroheavenofficial@gmail.com </span> <br/>
            <span id="clNmbr">Call Us: +92-316-7533717 </span> 
          </div>
          {/* Span with ids */}


          {/* Quick Links Section */}
          <div className="footer-col col-6 col-lg-2 mb-4">
          <h5 claasName='footer-links'>Quick Links</h5>
          <ul className="list-unstyled">
            <li><Link to="/privacy-policy" className="text-light">Privacy Policy</Link></li>
            <li><Link to="/terms" className="text-light">Terms of Service</Link></li>
          </ul>
          </div>


          {/* Other Pages Section */}
          <div className="footer-col col-6 col-lg-2 mb-4">
          <h5 claasName='footer-links'>Hydro Heaven</h5>
          <ul className="list-unstyled">
            <li><Link to="/home" className="text-light">Home</Link></li>
            <li><Link to="/services" className="text-light">Services</Link></li>
            <li><Link to="/blogs" className="text-light">Blogs</Link></li>
            <li><Link to="/contact" className="text-light">Contact Us</Link></li>
            <li><Link to="/faq" className="text-light">FAQs</Link></li>
          </ul>
          </div>
          
          {/* Newsletter Section */}
          <div className="footer-col col-lg-4 col-md-6 col-sm-12 mb-4">
            <h5>Newsletter</h5>
            <p>Get the latest news & updates</p>
          
            <form className="form-inline">
            <div className="input-group ">
              <input type="email" className="form-control" placeholder="Email" />
              
                
              <button type="button" className="btn btn-outline-light "> <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill me-3" viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
              </svg>
              </span>Subscribe</button>
               
            </div>
            </form>
          
            <div className="social-links mt-3">
          
            <a className="icons" href="https://www.facebook.com/people/Hydroheaven/100065165965909/?paipv=0eav=AfZefjIjpmXRSEj7HgE49HIN0EHVHUujV2vitDOSc0VHmVcjv4pVcgMqYjBSSoUCvcA&_rdr" aria-label="Facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" data-aos="fade-up" className="bi bi-facebook me-4" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
              </svg>
            </a>
            
            <a className="icons" href="https://www.instagram.com/officialhydroheaven/" aria-label="Instagram">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" data-aos="fade-up" className="bi bi-instagram me-4" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
              </svg>
            </a>
            
            <a className="icons" href="https://www.linkedin.com/in/hydro-heaven-3366b2313?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B3qK4%2B8aLQrmRZ8wbDwIyEQ%3D%3D" aria-label="LinkedIn">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" data-aos="fade-up" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
              </svg>
            </a>

            </div>
          
        </div>

      </div>
    </div>
  </footer>

);

}

