import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home'; 
import Services from './Pages/Services/Services';
import FAQs from './Pages/FAQs/FAQs';
import Contact from './Pages/Contact/Contact';
import Events from './Pages/Events/Events';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Products from './Pages/Products/Products';

// import DisableDevTools from './Components/DisableDevTool/DisableDevTool';

function App() {
  return (
    <Router>
    <ScrollToTop/>
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/home" element={<Home />} />
         <Route path="/services" element={<Services />} />
         <Route path="/products" element={<Products />} />
         <Route path="/contact" element={<Contact/>} />
         <Route path="/faq" element={<FAQs />} />
         <Route path='/events' element={<Events/>} />
       {/* <DisableDevTools/>  */}
       </Routes>
    </Router>
  );
}

export default App;
