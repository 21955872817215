import React from 'react';
import './ContactHeader.css'; // Ensure the CSS file is properly linked

const ContactHeader = () => {
  return (
      
        <header className="contact-header text-center">
          <div className="header-row">
            <div className="col">
              Mon-Fri (09:00-16:00 hrs)
            </div>
            
            <div className="col">
              Free Guidance
            </div>
            <div className="col">
              hydroheavenofficial@gmail.com
            </div>
          
          </div>
        </header>
      
  );
};

export default ContactHeader;
