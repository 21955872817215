import React from 'react'
import ContactHeader from '../../Components/Header/ContactHeader'
import Navbar from '../../Components/Navbar/Navbar'
import WhatsAppButton from '../../Components/Whatsapp/Whatsapp'
import ServiceSection from '../../Components/ServiceSection/ServiceSection'
import AdditionalServiceSection from '../../Components/AdditionalServiceSection/AdditionalServiceSection'
import CustomerReview from '../../Components/CustomerReviews/CustomerReview'
import Footer from '../../Components/Footer/Footer'




export default function services() {
    return (
    <>
     <div className="container-fluid p-0 m-0"> 
        <ContactHeader />
        <Navbar/>
        <WhatsAppButton/>
        <ServiceSection/>
        <AdditionalServiceSection/>
        <CustomerReview/>
        <Footer/>
    </div>
           
          
</>
    
)
}

