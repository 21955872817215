import React from 'react';
import './LandscapeComponent.css'; // Import the CSS file

import Tree from '../Images/Garden-4.jpeg';


const LandscapeComponent = () => {
  return (
  <div className="container-fluid landscape-area">
    <div className="row landscape-section">
      {/* Left Image Section */}
      
    
      <div className="landscape-image col-lg-5 col-md-12">
        <img src={Tree} alt="Landscape" />
      </div>

      {/* Right Text Section */}
      <div className="landscape-info col-lg-7 col-md-12">
        <p className="highlights">Why Hydro Heaven?</p>
        
        <h4 className="mainline">WE HAVE BEST SOLUTION FOR ALL YOUR ROOFTOP GARDENING NEEDS</h4>
            
        <div className="services row">
          <div className="service-item col-lg-6 col-md-12">
            <div className="icon">🌿</div>
            <p>Variety of Plants</p>
          </div>
          <div className="service-item col-lg-6 col-md-12">
            <div className="icon">👷‍♂️</div>
            <p>Expert Guidance</p>
          </div>
          <div className="service-item col-lg-6 col-md-12">
            <div className="icon">💸</div>
            <p>Affordable Price</p>
          </div>
          <div className="service-item col-lg-6 col-md-12">
            <div className="icon">👥</div>
            <p>Free Consultation</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default LandscapeComponent;
