import React from 'react'
import contactImgBg from '../Images/ProductsHero.jpg';
import contactImgSm from '../Images/ProductsHeroMb.png';
import './ProductsHero.css';

export default function ProductsHero() {
  return (
    <>
    <div className="container-fluid">
      <div className=" text-center">
        <img className='contactImgBg' src={contactImgBg} alt="background" />
        <img className='contactImgSm' src={contactImgSm} alt="background" />
        <h3 className='py-3 text-success fw-light'> We have these products to enhace the look of your place.</h3>
      </div>
      
    </div>
    </>
)
}
