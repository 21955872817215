import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo.png';
import './Navbar.css' ;

const Navbar = () => {
  return (

  <>

<nav className="navbar navbar-expand-lg navbar-light bg-body-success">
  <div className="container-fluid">
    
    <Link className="navbar-brand" to="/home">
      <img src={Logo} alt="Logo" className="d-inline-block align-text-center" />
    </Link>

    {/* Navbar Toggler for Small Screens */}
    <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    {/* Collapsible Navbar */}
    <div className="collapse navbar-collapse" id="navbarNav">
      
      <ul className="navbar-nav mx-auto"> {/* Centering nav-links using mx-auto */}
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/home">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services">Services</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/products">Products</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">Contact Us</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/faq">FAQs</Link>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/events" target="_blank" rel="noopener noreferrer">Events</a>
        </li>
      </ul>

      <a
        className="btn btn-outline-success ms-auto mt-2" href="https://wa.me/923226596698" role="button" target="_blank" rel="noopener noreferrer">Ask Query
      </a>
    </div>

  </div>
</nav>

  </>


  );
};

export default Navbar;

