import React from 'react';
import Compo from '../../Components/FaqCompo/FaqCompo';
import Header from '../../Components/Header/ContactHeader';
import Navbar from '../../Components/Navbar/Navbar';
import WhatsAppButton from '../../Components/Whatsapp/Whatsapp';
import Footer from '../../Components/Footer/Footer';

export default function FAQs() {
  return (
    <> 
        <div className='container-fluid p-0 m-0'>
          <Header />
          <Navbar />
          <WhatsAppButton />
          <Compo />
           <Footer />
        </div>
    </>
  )
}
